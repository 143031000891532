import request from '@/utils/request'

export function treeList(condition) {
  return request({
    url: '/system/group/treeList',
    method: 'post',
    data: { condition: condition }
  })
}


export function treeGroupList(condition) {
  return request({
    url: '/system/group/treeGroupList',
    method: 'post',
    data: { condition: condition }
  })
}
export function userTreeSelect(condition) {
  return request({
    url: '/system/group/userTreeSelect',
    method: 'post',
    data: { condition: condition }
  })
}
export function saveOrUpdate(param) {
  return request({
    url: '/system/group/saveOrUpdate',
    method: 'post',
    data: { param: param }
  })
}

/** 选部门 */
export function dapTreeSelect() {
  return request({
    url: '/system/group/dapTreeSelect',
    method: 'post',
    //data: { param: param }
  })
}
