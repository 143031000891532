<template>
  <el-card style="margin-bottom:20px;">
    <div slot="header" class="clearfix">
      <span>关于我</span>
    </div>

    <div class="user-profile">
      <div class="box-center">
        <el-upload
          class="avatar-uploader"
          :action="uploadUrl"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
        >
          <img v-if="avatar" :src="avatar" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon" />
        </el-upload>

      </div>
      <div class="box-center">
        <div class="user-name text-center">{{ user.name }}</div>
        <div class="user-role text-center text-muted">{{ user.role }}</div>
      </div>
    </div>

    <div class="user-bio">
      <el-descriptions title="基本信息" :column="1">
        <el-descriptions-item label="用户名">{{ user.username }}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{ user.mobile }}</el-descriptions-item>
        <el-descriptions-item label="电子邮箱">{{ user.email }}</el-descriptions-item>
        <el-descriptions-item label="所属机构">
          {{findNamesByIds(user.groupIds,groupTreeData).toString()}}
        </el-descriptions-item>
        <el-descriptions-item label="系统角色">
          {{ user.roleNames }}
        </el-descriptions-item>

        <!--        <el-descriptions-item label="账户类型">
          {{ format.formatEnumDesc("AccountTypeEnum",user.accountType) }}
        </el-descriptions-item>-->
        <el-descriptions-item v-if="user.accountType==='company'" label="所属分公司">
          <li v-for="(item) in user.branchOfficeList" style="list-style-type: none;">
            {{ item.name }}
          </li>
        </el-descriptions-item>
        <el-descriptions-item v-if="user.accountType==='site'" label="所属网点">
          <li v-for="(item) in user.branchOfficeList" style="list-style-type: none;">
            {{ item.name }}
          </li>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </el-card>
</template>

<script>
import PanThumb from '@/components/PanThumb'
import SingleImageUpload2 from '@/components/Upload/SingleImage2'
import SingleImageUpload3 from '@/components/Upload/SingleImage3'
import EditorSlideUpload from '@/components/Tinymce/components/EditorImage'
import ImageCropper from '@/components/ImageCropper'
import { changeAvatar } from '@/api/system/admin'
import store from '@/store'
import { mapGetters } from 'vuex'
import format from '@/utils/formatter'
import { treeList } from '@/api/system/group'
export default {
  components: { ImageCropper, EditorSlideUpload, SingleImageUpload3, SingleImageUpload2, PanThumb },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
          email: '',
          avatar: '',
          role: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'avatar'
    ])
  },
  created() {
    this.getTreeList()
  },
  data() {
    return {
      format: format,
      uploadUrl: process.env.VUE_APP_FILE_UPLOAD_URL,
      groupTreeData: []
    }
  },

  methods: {
    findNamesByIds(groups, list) {
      const result = []

      function traverse(node) {
        if (groups.includes(node.id)) {
          result.push(node.name)
        }
        if (node.children) {
          node.children.forEach(child => traverse(child))
        }
      }

      list.forEach(node => traverse(node))
      return result
    },
    getTreeList() {
      treeList().then((response) => {
        const { data } = response
        this.groupTreeData = data
      })
    },
    handleAvatarSuccess(response) {
      if (!response.code === 200) {
        this.$message.error(response.msg || response.message)
        return
      }
      const { fileName, url, md5 } = response.data
      changeAvatar(url).then((response) => {
        store.dispatch('user/changeAvatar', url)
        this.$message.success('修改头像成功')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-center {
  margin: 0 auto;
  display: table;
}

.text-muted {
  color: #777;
}

.user-profile {
  .user-name {
    font-weight: bold;
  }

  .box-center {
    padding-top: 10px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  .box-social {
    padding-top: 30px;

    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }

  .user-follow {
    padding-top: 20px;
  }
}

.user-bio {
  margin-top: 20px;
  color: #606266;

  span {
    padding-left: 4px;
  }

  .user-bio-section {
    font-size: 14px;
    padding: 15px 0;

    .user-bio-section-header {
      border-bottom: 1px solid #dfe6ec;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

</style>
