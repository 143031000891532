<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
/* .el-table__fixed,
.el-table__fixed-right {
  height: calc(100% - 11px) !important;
  -moz-height: calc(100% - 17px) !important;
  -ms-height: calc(100% - 17px) !important;
  -o-height: calc(100% - 17px) !important;
}

.el-table__fixed:before {
  content: '';
  height: 0 !important;
} */
</style>
