var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "消息通知",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "right-menu-item hover-effect",
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible = true
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-bell" }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "6px",
                              color: "red",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v(_vm._s(_vm.unreadNum))]
                        ),
                      ]
                    ),
                  ]
                ),
                _c("search", {
                  staticClass: "right-menu-item",
                  attrs: { id: "header-search" },
                }),
                _c("error-log", {
                  staticClass: "errLog-container right-menu-item hover-effect",
                }),
                _c("screenfull", {
                  staticClass: "right-menu-item hover-effect",
                  attrs: { id: "screenfull" },
                }),
                _c(
                  "el-tooltip",
                  {
                    attrs: {
                      content: "全局大小",
                      effect: "dark",
                      placement: "bottom",
                    },
                  },
                  [
                    _c("size-select", {
                      staticClass: "right-menu-item hover-effect",
                      attrs: { id: "size-select" },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item hover-effect",
              attrs: { trigger: "click" },
            },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [
                  _c(
                    "el-avatar",
                    { attrs: { size: "medium", src: _vm.avatar } },
                    [
                      _c("img", {
                        attrs: {
                          src: "https://cxyptest.gdyibo.com.cn/file/1f/72/07/41/1f720741ac52c100c5e93ad10ed6f307.png",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/profile/index" } },
                    [_c("el-dropdown-item", [_vm._v("个人中心")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.clearCache.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("清除缓存"),
                      ]),
                    ]
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v("首页")])],
                    1
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("verify-code"),
      _c("verify-reset-password"),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "通知",
            visible: _vm.dialogVisible,
            width: "52%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            _vm._l(_vm.list, function (item) {
              return _c(
                "el-collapse",
                {
                  on: {
                    change: function ($event) {
                      item.isRead === 0 ? _vm.changeRead(item.id) : ""
                    },
                  },
                  model: {
                    value: _vm.activeNames,
                    callback: function ($$v) {
                      _vm.activeNames = $$v
                    },
                    expression: "activeNames",
                  },
                },
                [
                  _c(
                    "el-collapse-item",
                    [
                      _c("template", { slot: "title" }, [
                        item.isRead === 0
                          ? _c("span", [
                              _vm._v(
                                "询价信息 时间：" + _vm._s(item.createTime)
                              ),
                            ])
                          : _vm._e(),
                        item.isRead === 1
                          ? _c("span", { staticStyle: { color: "#8e8b8b" } }, [
                              _vm._v(
                                "询价信息 时间：" + _vm._s(item.createTime)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      item.isRead === 1
                        ? _c("div", { staticStyle: { color: "#aca8a8" } }, [
                            _vm._v(
                              "你有新的询价信息，询价单号：" +
                                _vm._s(item.sn) +
                                "，请及时处理。"
                            ),
                          ])
                        : _vm._e(),
                      item.isRead === 0
                        ? _c("div", [
                            _vm._v(
                              "你有新的询价信息，询价单号：" +
                                _vm._s(item.sn) +
                                "，请及时处理。"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "el-row",
            { staticClass: "footer-box" },
            [
              _c(
                "el-col",
                { attrs: { span: 3 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.changeRead(0)
                        },
                      },
                    },
                    [_vm._v("全部已读")]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("pagination", {
                    attrs: {
                      total: _vm.pagination.total,
                      page: _vm.pagination.current,
                      limit: _vm.pagination.size,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.pagination, "current", $event)
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(_vm.pagination, "size", $event)
                      },
                      pagination: _vm.getlist,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }