import store from '@/store'

function checkPermission(el, binding) {
  // const { value } = binding.expression
  const value = binding.value
  const permissions = store.getters.permissions
  if (value != null && value.length > 0) {
    const hasPermission = permissions.includes(value)
    if (!hasPermission) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  } else {
    throw new Error(`need roles! Like v-permission="'CODE'"`)
  }
}

export const hasPermission = (value) => {
  const permissions = store.getters.permissions
  const hasPermission = permissions.includes(value)
  if (!hasPermission) {
    return false
  } else {
    return true
  }
}

export default {
  inserted(el, binding) {
    checkPermission(el, binding)
  },
  update(el, binding) {
    checkPermission(el, binding)
  }
}
