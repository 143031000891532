<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
               @toggleClick="toggleSideBar"/>

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container"/>
    <div class="right-menu">

      <template v-if="device!=='mobile'">
        <el-tooltip content="消息通知" effect="dark" placement="bottom">
          <div class="right-menu-item hover-effect" @click="dialogVisible = true">
            <i class="el-icon-bell"></i><span style="font-size: 6px;color: red;font-weight: bold">{{ unreadNum }}</span>
          </div>
        </el-tooltip>
        <search id="header-search" class="right-menu-item"/>
        <error-log class="errLog-container right-menu-item hover-effect"/>
        <screenfull id="screenfull" class="right-menu-item hover-effect"/>
        <el-tooltip content="全局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect"/>
        </el-tooltip>
      </template>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <el-avatar size="medium" :src="avatar">
            <img src="https://cxyptest.gdyibo.com.cn/file/1f/72/07/41/1f720741ac52c100c5e93ad10ed6f307.png"/>
          </el-avatar>
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/profile/index">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="clearCache">
            <span style="display:block;">清除缓存</span>
          </el-dropdown-item>
          <router-link to="/">
            <el-dropdown-item>首页</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>


    <verify-code/>
    <verify-reset-password/>
    <el-dialog
      title="通知"
      :visible.sync="dialogVisible"
      width="52%"
      :before-close="handleClose">
      <el-row>
        <el-collapse v-model="activeNames" v-for="(item) in list" @change="item.isRead===0?changeRead(item.id):''">
          <el-collapse-item >
            <template slot="title">
              <span v-if="item.isRead===0" >询价信息   时间：{{item.createTime}}</span>
              <span v-if="item.isRead===1" style="color: #8e8b8b">询价信息   时间：{{item.createTime}}</span>
            </template>
            <div v-if="item.isRead===1" style="color: #aca8a8">你有新的询价信息，询价单号：{{ item.sn }}，请及时处理。</div>
            <div v-if="item.isRead===0" >你有新的询价信息，询价单号：{{ item.sn }}，请及时处理。</div>
          </el-collapse-item>
        </el-collapse>
      </el-row>
      <el-row class="footer-box">
        <el-col :span="3">
        <el-button type="primary" @click="changeRead(0)">全部已读</el-button>
        </el-col>
        <el-col :span="5">
          <pagination
            :total="pagination.total"
            :page.sync="pagination.current"
            :limit.sync="pagination.size"
            @pagination="getlist"
          />
        </el-col>
      </el-row>
    </el-dialog>
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import ErrorLog from '@/components/ErrorLog'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch';
import Constant from '@/utils/constant.js'
import Ws from '@/utils/websocket.js'

import {loadEnum} from "@/utils/enum";
import VerifyCode from "@/layout/components/VerifyCode";
import VerifyResetPassword from "@/layout/components/VerifyResetPassword";

import Pagination from '@/components/Pagination'

export default {
  components: {
    Pagination,
    VerifyResetPassword,
    VerifyCode,
    Breadcrumb,
    Hamburger,
    ErrorLog,
    Screenfull,
    SizeSelect,
    Search
  },
  data() {
    return {
      loading: false,
      unreadNum: 0,
      list: [],
      condition:{},
      pagination: {
        total: 0,
        size: 10,
        current: 1
      },
      dialogVisible: false,
      outerVisible: false,
      innerVisible: false,
      showBell: false,
      activeNames: ['1'],
      showSystem: false,
      systemList: [],
      localStorageKey: Constant.localStorageKey
    }
  },
  mounted() {
    // this.getUnreadNum();
    // this.getlist();
    Ws.initWebSocket(this);
  },
  created() {
    this.systemList = this.$store.state.user.systemPath;
    if (this.systemList.length > 0) {
      this.showSystem = true;
      if (window.location.origin.indexOf("localhost") >= 0) {
        this.systemList.forEach(item => {
          item.systemPath = item.systemPathDev;
        })
      } else if (window.location.origin.indexOf("sit") >= 0) {
        this.systemList.forEach(item => {
          item.systemPath = item.systemPathSit;
        })
      } else {
        this.systemList.forEach(item => {
          item.systemPath = item.systemPathSit;
        })
      }
      console.log(this.systemList)
    } else {
      this.showSystem = false;
    }


  },

  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device'
    ])
  },
  methods: {
    handleClose(){},
    getUnreadNum() {
      getUnreadNum().then(response => {
        this.unreadNum = response.data;
      })
    },
    getlist() {
      this.loading = true
      getPage(this.condition, this.pagination).then((reponse) => {
        const {current, total, size, records} = reponse.data
        this.list = records
        this.pagination.total = total
        this.pagination.current = current
        this.pagination.size = size
      }).finally(() => {
        this.loading = false
      })
    },
    changeRead(id) {
      // debugger
      updateUnread(id).then((response) => {
        this.getUnreadNum();
        this.getlist();
      })
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    }, clickShowBell() {
      // debugger
      this.showBell = true;
    },
    clearCache() {

      let keys = this.localStorageKey;
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        window.localStorage.removeItem(key);
      }

      // 加载枚举信息
      loadEnum();

    }


  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 30px;
          height: 30px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
