import request from '@/utils/request'
import { aes } from '@/utils/crypto'
import { copy } from '@/utils/object'

export function login(data) {
  data.password = aes.encrypt(data.password)

  return request({
    url: '/system/admin/login',
    method: 'post',
    data
  })
}

export function ssoLogin(data) {

  return request({
    url: '/sso/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/system/admin/info',
    method: 'get',
    params: { token }
  })
}

export function logout() {
  return request({
    url: '/system/admin/logout',
    method: 'get'
  })
}

/**
 * 获取路由
 */
export function getRouters(data) {
  return request({
    url: '/system/admin/getRouters',
    method: 'post',
    data:data
  })
}

export function getPage(condition, pagination) {
  return request({
    url: '/system/admin/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function get(id) {
  return request({
    url: '/system/admin/get',
    method: 'post',
    data: { id: id }
  })
}

export function getAllAdmin() {
  return request({
    url: '/system/admin/getAll',
    method: 'post'
  })
}

export function getByRoleCodes(roleCodes, excludeRoleCodes) {
  return request({
    url: '/system/admin/getByRoleCodes',
    method: 'post',
    data: { roleCodes: roleCodes, excludeRoleCodes: excludeRoleCodes }
  })
}

/**
 * 重置密码
 */
export function resetPassword(id, password) {
  password = aes.encrypt(password)
  return request({
    url: '/system/admin/resetPassword',
    method: 'post',
    data: { id: id, password: password }
  })
}

export function saveOrUpdate(data) {
  data = copy(data)
  if (data.password != null) {
    data.password = aes.encrypt(data.password)
  }

  return request({
    url: '/system/admin/saveOrUpdate',
    method: 'post',
    data
  })
}

export function saveOrUpdatePt(data) {
  data = copy(data)
  if (data.password != null) {
    data.password = aes.encrypt(data.password)
  }

  return request({
    url: '/system/admin/saveOrUpdatePt',
    method: 'post',
    data
  })
}

/**
 * 个人档案
 */
export function profiles() {
  return request({
    url: '/system/admin/profiles',
    method: 'post'
  })
}

/**
 * 改变头像
 */
export function changeAvatar(url) {
  return request({
    url: '/system/admin/changeAvatar',
    method: 'post',
    data: { url: url }
  })
}

/**
 * 个人中心-修改密码
 */
export function updatePassword(oldPassword, password) {
  password = aes.encrypt(password)
  oldPassword = aes.encrypt(oldPassword)
  return request({
    url: '/system/admin/updatePassword',
    method: 'post',
    data: { password: password, oldPassword: oldPassword }
  })
}

/**
 * 个人中心-修改个人信息
 */
export function updateInfo(data) {
  return request({
    url: '/system/admin/updateInfo',
    method: 'post',
    data: data
  })
}

/**
 * 发送验证码
 * @returns {AxiosPromise}
 */
export function sendCode() {
  return request({
    url: '/system/admin/sendCode',
    method: 'post'
  })
}

/**
 * 校验验证码
 * @param code
 * @returns {AxiosPromise}
 */
export function validateCode(code) {
  return request({
    url: '/system/admin/validateCode',
    method: 'post',
    data: { code: code }
  })
}

/**
 * 根据组织架构id获取用户信息
 */
export function getInfoByGroupId(groupId,pagination) {
  return request({
    url: '/system/admin/getInfoByGroupId',
    method: 'post',
    data: { groupId: groupId,pagination: pagination}
  })
}

export function exportExcel(condition, pagination) {
  return request({
    url: '/system/admin/exportExcel',
    method: 'post',
    data: { condition: condition, pagination: pagination },
    responseType: 'blob'
  })
}