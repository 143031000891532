<template>
  <div class="singleImageUpload2 upload-container">
    <el-upload
      :multiple="false"
      :show-file-list="false"
      :on-success="handleImageSuccess"
      :before-upload="beforeAvatarUpload"
      :on-preview="handlePictureCardPreview"
      :on-remove="rmImage"
      class="image-uploader"
      drag
      :action="uploadUrl"
    >
      <i class="el-icon-upload" />
      <div class="el-upload__text"><em>点击上传</em></div>
    </el-upload>
    <div v-show="imageUrl" class="image-preview">
      <div v-show="imageUrl" class="image-preview-wrapper">
        <img :src="imageUrl" alt="">
        <div class="image-preview-action">
          <i class="el-icon-zoom-in" @click="handlePictureCardPreview(imageUrl)" />
          <i class="el-icon-delete" style="margin-left: 10px;" @click="rmImage" />
        </div>
      </div>
    </div>
    <el-dialog class="dialog" :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>

</template>

<script>

export default {
  name: 'SingleImageUpload2',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      uploadUrl: process.env.VUE_APP_FILE_UPLOAD_URL,
      dialogImageUrl: '',
      dialogVisible: false
    }
  },
  computed: {
    imageUrl() {
      return this.value
    }
  },
  methods: {
    rmImage() {
      this.imageUrl = ''
      console.log(this.imageUrl)
      this.emitInput('')
    },
    emitInput(val) {
      this.$emit('input', val)
    },
    handleImageSuccess(response) {
      if (!response.code === 200) {
        this.$message.error(response.msg || response.message)
        return
      }
      const { url } = response.data
      this.emitInput(url)
    },
    beforeAvatarUpload(file) {
      const types = ['image/jpeg', 'image/jpg', 'image/png']
      const isImage = types.includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isImage) {
        this.$message.error('上传图片只能是jpg,jpeg,png格式')
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isImage && isLt2M
    },
    handlePictureCardPreview(url) {
      this.dialogImageUrl = url
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-container {
  width: 100%;
  height: 100%;
  position: relative;
  .image-uploader {
    height: 100%;
  }
  .image-preview {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: 1px dashed #d9d9d9;
    .image-preview-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .image-preview-action {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0, 0, 0, .5);
      transition: opacity .3s;
      cursor: pointer;
      line-height: 200px;
      .el-icon-delete {
        font-size: 36px;
      }
      .el-icon-zoom-in{
        font-size: 36px;
      }
    }
    &:hover {
      .image-preview-action {
        opacity: 1;
      }
    }
  }
}
</style>
