import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=3f0ce8ca"
import script from "./Account.vue?vue&type=script&lang=js"
export * from "./Account.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\三菱\\product_vue\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f0ce8ca')) {
      api.createRecord('3f0ce8ca', component.options)
    } else {
      api.reload('3f0ce8ca', component.options)
    }
    module.hot.accept("./Account.vue?vue&type=template&id=3f0ce8ca", function () {
      api.rerender('3f0ce8ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/fwpt/profile/components/Account.vue"
export default component.exports